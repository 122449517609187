import React, { useEffect, useRef, useState } from "react";
import { compareDate, formatDate, formatDateEn2 } from "../utils/dateUtil";
import { formatTime } from "../utils/timeUtil";
import { inputMsg, reservation, unavailableStay } from "../constants";
import SparkMD5 from "spark-md5";
import Avatar from "./Avatar";
import Loading from "./Loading/Loading";
import { checkJson } from "../utils/util";
import useMobile from "../hooks/useMobile";

const PhotoBox = ({ url, setIsOpenModal, setSelectedPhoto }) => {
  return (
    <div
      onClick={() => {
        setIsOpenModal(true);
        setSelectedPhoto(url);
      }}
      className={`border border-[#e6e6e6] rounded-lg basis-56 cursor-pointer w-[220px] h-[330px]`}
    >
      <img
        className="rounded-lg"
        src={url}
        alt="Message detail"
        onLoad={(e) => {
          e.target.parentNode.classList.remove("w-[220px]");
          e.target.parentNode.classList.remove("h-[330px]");
        }}
      />
    </div>
  );
};

const Chat = ({
  Header,
  messageList,
  fetchMessages,
  sendMessage,
  sendSeen,
  getUploadLink,
}) => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  const locale = params.locale;
  const isMobile = useMobile();
  const textareaEl = useRef();
  const inputEl = useRef();
  const divRef = useRef();
  const sendBtnRef = useRef();
  const uploadRef = useRef();
  const [files, setFiles] = useState({});
  const [preview, setPreview] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleTouchMove = () => {
      let scheduledAnimationFrame = false;

      if (scheduledAnimationFrame) return;
      scheduledAnimationFrame = true;

      requestAnimationFrame(() => {
        textareaEl.current.blur();
        scheduledAnimationFrame = false;
      });
    };

    document.addEventListener("touchmove", handleTouchMove, {
      passive: true,
    });

    return () => {
      document.removeEventListener("touchmove", handleTouchMove, {
        passive: true,
      });
    };
  }, []);

  useEffect(() => {
    if (messageList.length !== 0) {
      divRef.current.scrollIntoView(false);
      divRef.current.style.opacity = "1";
    }

    for (const m of messageList) {
      if (!m.fromClient && !m.seen) {
        try {
          sendSeen(m.mId);
        } catch (error) {
          console.log(error);
        }
        break;
      }
    }
  }, [messageList, sendSeen]);

  useEffect(() => {
    fetchMessages();
    const refetch = () => {
      if (!document.hidden) {
        fetchMessages();
      }
    };
    document.addEventListener("visibilitychange", refetch);
    return () => {
      document.removeEventListener("visibilitychange", refetch);
    };
  }, [fetchMessages]);

  useEffect(() => {
    if (!preview.length) {
      setIsLoading(false);
      sendBtnRef?.current?.classList.add("invert");
      sendBtnRef?.current?.classList.remove("invert-1");
    } else {
      sendBtnRef?.current?.classList.add("invert-1");
      sendBtnRef?.current?.classList.remove("invert");
    }
  }, [preview]);

  useEffect(() => {
    if (uploadedFiles.length > 0) {
      uploadedFiles.forEach((img) => {
        const imgMsg = JSON.stringify({
          type: "photo",
          url: img,
        });

        sendMessage("Photo\n" + imgMsg);
      });

      setFiles({});
      setPreview([]);
      setUploadedFiles([]);
    }
  }, [uploadedFiles, sendMessage]);

  const handleFileChange = (e) => {
    if (e.target.files) {
      if (e.target.files.length > 10) {
        alert("이미지는 최대 10개까지 업로드가 가능합니다.");
        return;
      }

      setFiles({});
      setPreview([]);
      setUploadedFiles([]);

      for (const f of e.target.files) {
        const reader = new FileReader();

        computeChecksum(f);
        reader.onload = (e) => setPreview((pre) => [...pre, e.target.result]);
        reader.readAsDataURL(f);
      }
    }
  };

  const computeChecksum = (file) => {
    let blobSlice =
        File.prototype.slice ||
        File.prototype.mozSlice ||
        File.prototype.webkitSlice,
      chunkSize = 2097152,
      chunks = Math.ceil(file.size / chunkSize),
      currentChunk = 0,
      spark = new SparkMD5.ArrayBuffer(),
      fileReader = new FileReader();

    fileReader.onload = function (e) {
      spark.append(e.target.result);
      currentChunk++;

      if (currentChunk < chunks) {
        loadNext();
      } else {
        setFiles((h) => {
          h[btoa(spark.end(true))] = file;
          return h;
        });
      }
    };

    fileReader.onerror = function () {
      console.warn("oops, something went wrong.");
    };

    function loadNext() {
      const start = currentChunk * chunkSize,
        end = start + chunkSize >= file.size ? file.size : start + chunkSize;

      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
    }

    loadNext();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (Object.keys(files).length > 0) {
      setIsLoading(true);

      getUploadLink(
        JSON.stringify({
          files: Object.entries(files).map(([checksum, file]) => ({
            filename: file.name,
            byte_size: file.size,
            checksum: checksum,
            content_type: file.type,
            metadata: { attachment_for: "chat" },
          })),
        })
      ).then((data) => {
        const result = [];

        for (const f of data) {
          fetch(f.direct_upload.url, {
            method: "PUT",
            headers: f.direct_upload.headers,
            body: files[f.checksum],
          })
            .then((d) => {
              if (d.ok) {
                result.push(f.public_url);

                if (data.length === result.length) {
                  setUploadedFiles([...result]);
                }
              } else {
                setIsLoading(false);
              }
            })
            .catch(() => setIsLoading(false));
        }
      });
    }

    if (textareaEl.current) {
      const inputVal = textareaEl.current.value;
      const isEmpty = inputVal.split("\n").every((val) => !val);

      //한국어 버퍼 문제
      inputEl.current.focus();
      textareaEl.current.focus();

      if (!isEmpty) {
        sendMessage(inputVal);
      }

      textareaEl.current.value = "";
      textareaEl.current.style.height = "40px";
    }
  };

  const PhotoModal = ({ url }) => {
    return (
      <div
        className={`${
          isMobile ? "w-full" : "w-[66.7%]"
        } h-full z-30 bg-[#333] fixed top-0 flex justify-center items-center`}
      >
        <img
          src="img/modal-close.svg"
          className="absolute right-3.5 top-3.5 cursor-pointer"
          onClick={() => setIsOpenModal(false)}
          alt="Modal close button"
        />
        <img src={url} className="max-h-[87%]" alt="Message detail" />
      </div>
    );
  };

  const PreviewBox = ({ url, idx }) => {
    const handleClick = (e) => {
      let removeTarget = "";

      Object.entries(files).forEach((val, i) => {
        if (i === Number(e.target.dataset.idx)) {
          removeTarget = val[0];
        }
      });

      setFiles((files) => {
        delete files[removeTarget];
        return files;
      });

      setPreview((pre) => {
        pre.splice(e.target.dataset.idx, 1);
        return [...pre];
      });
    };

    return (
      <img
        src={url}
        data-idx={idx}
        alt="preview"
        className={`w-28 mr-1 flex-[0_0_auto] rounded-lg ${
          !isMobile && "cursor-pointer"
        }`}
        onClick={(e) => handleClick(e)}
      />
    );
  };

  return (
    <div ref={divRef} className="w-full overflow-auto relative pb-16 opacity-0">
      {isLoading && (
        <div
          className={`fixed ${
            isMobile ? "w-full" : "w-[68%]"
          } h-full z-20 bg-[#ccc]/30 flex justify-center items-center`}
        >
          <Loading />
        </div>
      )}
      {isOpenModal && <PhotoModal url={selectedPhoto} />}
      {isMobile && <Header />}
      <div className="flex flex-col-reverse p-4 min-h-max pt-16">
        <div>
          {messageList.map((m, i) => {
            const isImg = checkJson(m.value);
            let imgUrl = "";

            if (isImg) {
              const imgValue = JSON.parse(m.value.split("\n")[1]);
              imgUrl = imgValue.url;
            }

            const isDiff =
              i > 0 &&
              compareDate(messageList[i - 1].createdAt) <
                compareDate(m.createdAt);

            const timeLine = (
              <div className="flex relative mt-4 mb-4">
                <div className="text-time text-sm px-2 z-10 bg-white mx-auto my-0">
                  {locale === "ko"
                    ? formatDate(m.createdAt, true)
                    : formatDateEn2(m.createdAt, true)}
                </div>
                <hr className="w-full absolute top-1/2" />
              </div>
            );

            if (m.fromClient) {
              return (
                <div key={i}>
                  {(!i || isDiff) && timeLine}
                  <div className="flex w-full mt-2 space-x-3 max-w-xs ml-auto justify-end">
                    <div className="flex flex-row-reverse items-end">
                      {isImg ? (
                        <PhotoBox
                          url={imgUrl}
                          setIsOpenModal={setIsOpenModal}
                          setSelectedPhoto={setSelectedPhoto}
                        />
                      ) : (
                        <div className="bg-me text-white p-3 rounded-lg basis-56 break-all">
                          <p className="text-sm text-left">
                            {m.value.split("\n").map((line, i) =>
                              line.slice(0, 1) === "{" &&
                              line.slice(-1) === "}" ? (
                                <></>
                              ) : (
                                <span key={i}>
                                  {line}
                                  <br />
                                </span>
                              )
                            )}
                          </p>
                        </div>
                      )}
                      <span className="text-xs text-time leading-none basis-10 whitespace-nowrap mr-1">
                        {formatTime(m.createdAt)}
                      </span>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div key={i}>
                  {(!i || isDiff) && timeLine}
                  {m.isAlert ? (
                    <div className="text-xs text-white w-fit bg-[#999] px-3 py-1 rounded-2xl mt-5 mb-5 mx-auto max-w-[275px]">
                      {
                        <span>
                          <p
                            className="leading-relaxed"
                            dangerouslySetInnerHTML={{ __html: m.value }}
                          ></p>
                        </span>
                      }
                    </div>
                  ) : (
                    <div className="flex w-full mt-2 space-x-3">
                      <Avatar
                        bookingId={m.bookingId}
                        chatroomName={m.chatroomName}
                        size={"small"}
                        mode={params.mode}
                      />
                      <div className="flex items-end max-w-[80%]">
                        {isImg ? (
                          <PhotoBox
                            url={imgUrl}
                            setIsOpenModal={setIsOpenModal}
                            setSelectedPhoto={setSelectedPhoto}
                          />
                        ) : (
                          <div className="bg-you p-3 rounded-lg text-tahiti-100 basis-56 break-all">
                            {m.value.split("\n").map((line, i) =>
                              line.slice(0, 1) === "{" &&
                              line.slice(-1) === "}" ? (
                                <a
                                  href={line.slice(1, -1)}
                                  target="_parent"
                                  rel="noreferrer"
                                >
                                  <div className="leading-9 bg-[#666] text-white text-sm rounded-md mt-4">
                                    {reservation[locale]}
                                  </div>
                                </a>
                              ) : (
                                <p key={i} className="text-sm text-left">
                                  <span>
                                    {line}
                                    <br />
                                  </span>
                                </p>
                              )
                            )}
                          </div>
                        )}

                        <span className="text-xs text-time leading-none basis-10 whitespace-nowrap ml-1">
                          {formatTime(m.createdAt)}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              );
            }
          })}
        </div>
      </div>
      <form
        className={`bg-white md:p-2.5 pr-1 p-2.5 ${
          isMobile && "pb-5"
        } flex items-center border-t border-x border-gray-200 fixed bottom-0 z-20 w-full`}
      >
        {messageList[0]?.chatEnabled ? (
          <>
            <button
              className="cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                uploadRef.current.click();
              }}
            >
              <img src="/img/upload-btn.svg" alt="Upload icon" />
            </button>
            <input
              id="file"
              type="file"
              onChange={handleFileChange}
              multiple
              accept="image/*"
              className="hidden"
              ref={uploadRef}
            />
            {preview.length > 0 ? (
              <div
                className={`${
                  isMobile ? "w-full" : "flex-auto w-[25%]"
                } flex flex-nowrap overflow-x-auto`}
              >
                {preview.map((url, i) => (
                  <PreviewBox url={url} idx={i} />
                ))}
              </div>
            ) : (
              <textarea
                ref={textareaEl}
                maxLength="800"
                placeholder={inputMsg[locale]}
                className={`h-10 flex-auto rounded p-2 bg-input resize-none outline-none focus:border-2 focus:border-[#333] max-h-24 ${
                  isMobile ? "w-full" : "w-[25%]"
                }`}
                onFocus={(e) => {
                  if (isMobile) e.target.parentNode.classList.remove("pb-5");
                  sendBtnRef.current.classList.add("invert-1");
                  sendBtnRef.current.classList.remove("invert");
                }}
                onBlur={(e) => {
                  if (isMobile) e.target.parentNode.classList.add("pb-5");
                  sendBtnRef.current.classList.add("invert");
                  sendBtnRef.current.classList.remove("invert-1");
                }}
                onKeyDown={(e) => {
                  if (isMobile) return;

                  if (
                    e.key === "Enter" &&
                    !e.shiftKey &&
                    !e.nativeEvent.isComposing
                  ) {
                    handleSubmit(e);
                  }
                }}
                onInput={(e) => {
                  const lines = e.target.value.split(/\r\n|\r|\n/).length;
                  const rounds = Math.round(
                    textareaEl.current.scrollHeight / 30
                  );

                  if (rounds > lines) {
                    return (textareaEl.current.style.height = `${
                      rounds * 30
                    }px`);
                  }

                  if (lines === 1) {
                    return (textareaEl.current.style.height = "40px");
                  }

                  if (lines > 1) {
                    textareaEl.current.style.height = `${lines * 30}px`;
                  }
                }}
              />
            )}
            <button
              onClick={(e) => handleSubmit(e)}
              className="flex-auto"
              disabled={isLoading}
            >
              <img
                ref={sendBtnRef}
                src="/img/send-bk800.svg"
                className="Frame-1 invert"
                alt="send-button"
              />
            </button>
            <input
              className="w-px opacity-0 pointer-events-none"
              ref={inputEl}
            />
          </>
        ) : (
          <div className="text-sm text-[#666] pb-4 pt-2 sm:basis-8/12 basis-full">
            {unavailableStay[locale]}
          </div>
        )}
      </form>
    </div>
  );
};

export default Chat;
