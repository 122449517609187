import React, { useState } from "react";
import Avvvatars from "avvvatars-react";

const Avatar = ({ bookingId, chatroomName, size, mode }) => {
  const [hasLogo, setHasLogo] = useState(true);

  const handleError = () => setHasLogo(false);

  return hasLogo && mode !== "host" ? (
    <img
      className={
        size === "medium"
          ? "border-solid border-[1px] object-contain h-[48px] w-[48px] rounded-[20px]"
          : "border-solid border-[1px] object-contain h-[40px] w-[40px] rounded-[16px]"
      }
      src={`https://www.stayfolio.com/api/v1/chat/bookings/${bookingId}/logo`}
      onError={handleError}
      alt="logo"
    />
  ) : (
    <Avvvatars
      value={chatroomName}
      displayValue={chatroomName.slice(-2)}
      size={size === "medium" ? 48 : 40}
      radius={size === "medium" ? 20 : 16}
    />
  );
};

export default Avatar;
