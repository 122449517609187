import React, { useEffect, useLayoutEffect, useRef } from "react";
import { formatTime } from "../utils/timeUtil";
import {
  formatDate2,
  formatDateEn,
  formatDateEnWithTimezone,
  formatDateWithTimezone,
  isSameDate,
  isSameYear,
} from "../utils/dateUtil";
import { emptyMsg, photo } from "../constants";
import Avatar from "./Avatar";
import { checkJson } from "../utils/util";

const ChatList = ({ handleClickRoom, roomList, fetchRoomList }) => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  const { locale } = params;
  const copyFormatDate = locale === "ko" ? formatDate2 : formatDateEn;

  useEffect(() => {
    fetchRoomList();
    const refetch = () => {
      if (!document.hidden) {
        fetchRoomList();
      }
    };
    document.addEventListener("visibilitychange", refetch);
    return () => {
      document.removeEventListener("visibilitychange", refetch);
    };
  }, [fetchRoomList]);

  const listRef = useRef();
  useLayoutEffect(() => {
    listRef.current.scrollTop = localStorage.getItem("sfchat_scrollPos");
  });

  return (
    <div
      className={`flex flex-col flex-grow w-full max-w-3xl bg-white border-r overflow-hidden h-screen`}
    >
      <div
        className="flex flex-col flex-grow h-0 overflow-auto sm:pt-14 pb-10"
        ref={listRef}
        onScroll={(e) => {
          localStorage.setItem("sfchat_scrollPos", e.currentTarget.scrollTop);
        }}
      >
        {roomList &&
          (!roomList.length ? (
            <p className="text-time mt-8">{emptyMsg[locale]}</p>
          ) : (
            <ul>
              {roomList.map(([roomId, _, m]) => {
                const isImg = checkJson(m.value);

                return (
                  <li
                    id={m.bookingId}
                    key={roomId}
                    className="w-full flex items-center p-3 cursor-pointer relative active:bg-[#f2f2f2]"
                    onClick={() => {
                      handleClickRoom(roomId);
                    }}
                  >
                    <Avatar
                      bookingId={m.bookingId}
                      chatroomName={m.chatroomName}
                      size={"medium"}
                      mode={params.mode}
                    />
                    <div className="flex flex-col justify-around w-9/12 ml-3 text-left h-[67px] truncate">
                      <p>
                        <span
                          className={`text-base font-bold text-stone-600 mr-2 ${
                            !m.fromClient && !m.seen && "text-black"
                          }`}
                        >
                          {m.chatroomName}
                        </span>
                        <span className="text-sm text-[#b3b3b3]">
                          {isSameDate(m.createdAt)
                            ? formatTime(m.createdAt)
                            : isSameYear(m.createdAt)
                            ? copyFormatDate(m.createdAt, false)
                            : copyFormatDate(m.createdAt, true)}
                        </span>
                      </p>
                      <p
                        className={`text-sm text-[#999999] truncate ${
                          !m.fromClient && !m.seen && "font-bold text-[#333333]"
                        }`}
                      >
                        {isImg ? photo[locale] : m.value.split("\n")[0]}
                      </p>
                      <p className="text-xs text-[#666666] truncate">
                        {locale === "ko"
                          ? formatDateWithTimezone(m.checkIn, false)
                          : isSameYear(m.checkIn)
                          ? formatDateEnWithTimezone(m.checkIn, false)
                          : formatDateEnWithTimezone(m.checkIn, true)}
                        {" - "}
                        {locale === "ko"
                          ? formatDateWithTimezone(m.checkOut, false)
                          : isSameYear(m.checkOut)
                          ? formatDateEnWithTimezone(m.checkOut, false)
                          : formatDateEnWithTimezone(m.checkOut, true)}{" "}
                        / {m.roomName}
                      </p>
                    </div>
                    {!m.fromClient && !m.seen && (
                      <span className="bg-[#f06d6a] w-2 h-2 rounded-full absolute right-4 top-11" />
                    )}
                  </li>
                );
              })}
            </ul>
          ))}
      </div>
    </div>
  );
};

export default ChatList;
