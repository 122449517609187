import "./App.css";
import React, { useState, useCallback } from "react";
import ChatList from "./components/ChatList";
import Chat from "./components/Chat";
import useChat from "./hooks/useChat";
import { msg } from "./constants";
import useMobile from "./hooks/useMobile";
import { useNavigate } from "react-router-dom";

function App() {
  const isMobile = useMobile();
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  const locale = params.locale;
  const [selectedRoomId, setSelectedRoomId] = useState(
    parseInt(params.roomId) || null
  );
  const navigate = useNavigate();

  const [
    roomList,
    fetchRoomList,
    getMessages,
    fetchMessagesFrom,
    makeSendMessage,
    makeSendSeen,
    getUploadLink,
  ] = useChat(params.token, params.mode);
  const messageList = getMessages(selectedRoomId);
  const fetchMessages = useCallback(
    () => fetchMessagesFrom(selectedRoomId),
    [fetchMessagesFrom, selectedRoomId]
  );
  const sendMessage = makeSendMessage(selectedRoomId);
  const sendSeen = makeSendSeen(selectedRoomId);
  const isFromBookingWithPc = params.from === "BOOKING" && !isMobile;

  const Header = () => {
    const handleClickBack = () => {
      if (selectedRoomId) {
        const referrer = new URL(document.referrer);
        if (referrer.hostname === "www.stayfolio.com") {
          window.location.href = "https://www.stayfolio.com/chat";
        } else if (referrer.hostname === "host.stayfolio.com") {
          if (params.from === "BOOKING" && params.mode === "host") {
            navigate(-1);
          } else {
            window.location.href = "https://host.stayfolio.com/chat";
          }
        } else {
          window.history.back();
        }
      } else {
        window.location.href = `https://www.stayfolio.com/mypage`;
      }
    };

    return (
      <>
        <div
          className={`flex items-center h-[52px] bg-white border-b border-b-gray-200 top-0 z-20 w-full ${
            !selectedRoomId ? "fixed max-w-xl" : isMobile ? "fixed" : "fixed"
          }`}
        >
          {!isFromBookingWithPc && (
            <button onClick={() => handleClickBack()}>
              <img src="img/back.svg" className="back" alt="back" />
            </button>
          )}
          <p className="mx-auto my-0 text-lg font-bold text-[#333] -translate-x-6">
            {selectedRoomId ? messageList[0]?.chatroomName : msg[locale]}
          </p>
        </div>
      </>
    );
  };

  const DesktopHeader = () => {
    return (
      <>
        <div
          className={`flex items-center h-14 bg-white border-b border-b-gray-200 top-0 z-20 w-full fixed justify-between mb-80`}
        >
          {params.mode === "guest" && (
            <div className="flex items-center border-r border-b-gray-200 h-14 w-[33.5%]">
              <>
                <button
                  onClick={() => {
                    window.history.back();
                  }}
                >
                  <img src="img/back.svg" className="back" alt="back" />
                </button>
                <p className="font-bold text-left color-[#333]">
                  {msg[locale]}
                </p>
              </>
            </div>
          )}
          <p
            className={`basis-8/12 text-base text-center color-[#666] font-bold ${
              params.mode === "host" && "basis-full"
            }`}
          >
            {selectedRoomId
              ? messageList[0].chatroomName
              : setSelectedRoomId(roomList?.[0]?.[0])}
          </p>
        </div>
      </>
    );
  };

  return (
    <div className="App">
      {isMobile && selectedRoomId && (
        <Chat
          Header={Header}
          messageList={messageList}
          fetchMessages={fetchMessages}
          sendMessage={sendMessage}
          sendSeen={sendSeen}
          getUploadLink={getUploadLink}
        />
      )}
      {isMobile && !selectedRoomId && (
        <ChatList
          handleClickRoom={(id) => {
            window.parent.location.href =
              window.location.origin + window.location.search + "&roomId=" + id;
          }}
          roomList={roomList}
          fetchRoomList={fetchRoomList}
        />
      )}
      {/* params.roomId를 통해 click 여부를 판별 */}
      {!isMobile && params.roomId && (
        <Chat
          Header={Header}
          messageList={messageList}
          fetchMessages={fetchMessages}
          sendMessage={sendMessage}
          sendSeen={sendSeen}
          getUploadLink={getUploadLink}
        />
      )}
      {!isMobile && !params.roomId && (
        <>
          <DesktopHeader />
          <div className="flex justify-end pb-90">
            <div className="basis-4/12 fixed left-0 w-[33.4%]">
              <ChatList
                handleClickRoom={setSelectedRoomId}
                roomList={roomList}
                fetchRoomList={fetchRoomList}
              />
            </div>
            <div className="basis-8/12">
              {selectedRoomId ? (
                <Chat
                  Header={Header}
                  messageList={messageList}
                  fetchMessages={fetchMessages}
                  sendMessage={sendMessage}
                  sendSeen={sendSeen}
                  getUploadLink={getUploadLink}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default App;
