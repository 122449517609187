const useMobile = () => {
  const isApp =
    /stayfolio-ios|stayhost-ios|stayfolio-android|stayhost-android/i.test(
      navigator.userAgent
    );
  const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent);

  return isApp || isMobile;
};

export default useMobile;
