import { useState, useRef, useEffect, useCallback } from "react";

const useChat = (token, mode) => {
  const [chatState, setChatState] = useState(null);
  const [reconnecting, setReconnecting] = useState(1);
  let ws = useRef(null);

  const TYPE_MESSAGE = "M";
  const TYPE_SEEN = "S";

  const roomList = chatState
    ? Object.entries(chatState)
        .map(([roomId, messageMap]) => {
          const maxMId = Math.max(...Object.keys(messageMap));
          return [parseInt(roomId), maxMId, messageMap[maxMId]];
        })
        .sort((x, y) => y[1] - x[1])
    : null;

  const fetchRoomList = useCallback(
    () =>
      fetch("/rooms?mode=" + mode, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setChatState((prevState) => {
            const newState = JSON.parse(JSON.stringify(prevState || {}));
            for (const m of data) {
              newState[m.roomId] = { ...newState[m.roomId], [m.mId]: m };
            }
            return newState;
          });
        }),
    [token, mode]
  );

  const getMessages = (roomId) => {
    if (roomId) {
      return Object.values((chatState || {})[roomId] || {});
    } else {
      return [];
    }
  };

  const fetchMessagesFrom = useCallback(
    (roomId) =>
      fetch("/messages?mode=" + mode + "&roomId=" + roomId, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setChatState((prevState) => {
            const newState = JSON.parse(JSON.stringify(prevState || {}));
            for (const m of data) {
              newState[m.roomId] = { ...newState[m.roomId], [m.mId]: m };
            }
            return newState;
          });
        }),
    [token, mode]
  );

  const makeSendMessage = (roomId) => {
    if (roomId) {
      return (message) =>
        ws.current.send(
          TYPE_MESSAGE + JSON.stringify({ roomId: roomId, value: message })
        );
    } else {
      return () => {};
    }
  };

  const makeSendSeen = (roomId) => (mId) =>
    ws.current.send(TYPE_SEEN + JSON.stringify({ mId: mId, roomId: roomId }));

  const getUploadLink = useCallback(
    (body) =>
      fetch("/upload", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: body,
      }).then((response) => response.json()),
    [token]
  );

  useEffect(() => {
    if (reconnecting) {
      const isDev = process.env.NODE_ENV === "development";
      ws.current = new WebSocket(
        `${
          isDev
            ? `ws://${window.location.hostname}:8080`
            : `wss://${window.location.host}`
        }/ws?token=${token}&mode=${mode}`
      );
      ws.current.onopen = () => {
        setReconnecting(0);
        console.log("connected to ws");
      };
      ws.current.onclose = (error) => {
        console.log("disconnect from ws", error);
        // Instead of reconnecting, display a button that can refresh page
        // if (error.code !== 3001) {
        //   setTimeout(() => setReconnecting((count) => count + 1), 1000);
        // }
      };
      ws.current.onerror = (error) => {
        console.log("connection error ws", error);
      };
      ws.current.onmessage = (evt) => {
        const m = JSON.parse(evt.data);
        if (m.type !== "error") {
          setChatState((prevState) => {
            const newState = JSON.parse(JSON.stringify(prevState || {}));
            newState[m.roomId] = { ...newState[m.roomId], [m.mId]: m };
            return newState;
          });
        }
      };
      document.addEventListener("visibilitychange", () => {
        if (document.hidden) {
          ws.current.close(3001);
        } else {
          setReconnecting((count) => count + 1);
        }
      });
    }
  }, [mode, reconnecting, token]);

  return [
    roomList,
    fetchRoomList,
    getMessages,
    fetchMessagesFrom,
    makeSendMessage,
    makeSendSeen,
    getUploadLink,
  ];
};
export default useChat;
