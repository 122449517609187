export const inputMsg = {
  ko: "메시지를 입력하세요.",
  en: "Please enter your message",
  ja: "メッセージを入力してください。",
};

export const unavailableStay = {
  ko: "채팅 상담이 불가능한 스테이입니다.",
  en: "Message feature is disabled for this stay.",
  ja: "チャット相談が不可能なステイです。",
};

export const reservation = {
  ko: "예약상세",
  en: "Reservation Details",
  ja: "予約内容",
};

export const emptyMsg = {
  ko: "메시지가 없습니다.",
  en: "Messages empty.",
  ja: "メッセージがありません。",
};

export const msg = {
  ko: "메시지",
  en: "Message",
  ja: "メッセージ",
};

export const photo = {
  ko: "사진",
  en: "Photo",
  ja: "写真",
};
