export const formatTime = (stamp) => {
  const date = new Date(stamp * 1000);
  let apm = "pm";
  let hour = date.getHours();
  const minute = "0" + date.getMinutes();

  if (hour < 12) {
    apm = "am";
  }

  if (hour > 12) {
    hour -= 12;
  }

  return hour + ":" + minute.substring(minute.length - 2) + " " + apm;
};
