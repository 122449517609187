import dayjs from "dayjs";

export const formatDate = (stamp, withWeek) => {
  const currentDate = new Date();
  const date =
    typeof stamp === "number" ? new Date(stamp * 1000) : new Date(stamp);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  let week = date.getDay();

  switch (week) {
    case 0:
      week = "일";
      break;
    case 1:
      week = "월";
      break;
    case 2:
      week = "화";
      break;
    case 3:
      week = "수";
      break;
    case 4:
      week = "목";
      break;
    case 5:
      week = "금";
      break;
    case 6:
      week = "토";
      break;
    default:
      week = "";
  }

  const displayYear = currentDate.getFullYear() !== year ? year + "년 " : "";

  if (withWeek) {
    return year + "년 " + month + "월 " + day + "일 " + week + "요일";
  } else {
    return displayYear + month + "월 " + day + "일 ";
  }
};

export const formatDate2 = (stamp, withYear) => {
  const date =
    typeof stamp === "number" ? new Date(stamp * 1000) : new Date(stamp);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  if (withYear) {
    return year + ". " + month + ". " + day + ".";
  } else {
    return month + "월 " + day + "일";
  }
};

export const formatDateEn = (stamp, withYear) => {
  const date =
    typeof stamp === "number" ? new Date(stamp * 1000) : new Date(stamp);
  const year = date.getFullYear();
  let month = date.getMonth();
  const day = date.getDate();

  switch (month) {
    case 0:
      month = "Jan.";
      break;
    case 1:
      month = "Feb.";
      break;
    case 2:
      month = "Mar.";
      break;
    case 3:
      month = "Apr.";
      break;
    case 4:
      month = "May.";
      break;
    case 5:
      month = "Jun.";
      break;
    case 6:
      month = "Jul.";
      break;
    case 7:
      month = "Aug.";
      break;
    case 8:
      month = "Sept.";
      break;
    case 9:
      month = "Oct.";
      break;
    case 10:
      month = "Nov.";
      break;
    case 11:
      month = "Dec.";
      break;
    default:
      month = "";
  }

  if (withYear) {
    return month + " " + day + ", " + year;
  } else {
    return month + " " + day;
  }
};

// TODO: 추후 함수 하나에서 ko/en에 대한 로직을 한번에 처리할 수 있도록 리팩토링 해야함
export const formatDateWithTimezone = (stamp, withWeek) => {
  const currentDate = dayjs().tz("Asia/Seoul");
  const date = dayjs(stamp).tz("Asia/Seoul");

  const year = date.year();
  const month = date.month() + 1;
  const day = date.date();
  let week = date.day();

  const dayList = ["일", "월", "화", "수", "목", "금", "토"];
  week = dayList.at(week) ?? "";

  const displayYear = currentDate.year() !== year ? year + "년 " : "";

  if (withWeek) {
    return year + "년 " + month + "월 " + day + "일 " + week + "요일";
  } else {
    return displayYear + month + "월 " + day + "일 ";
  }
};

export const formatDateEnWithTimezone = (stamp, withYear) => {
  const date = dayjs(stamp).tz("Asia/Seoul");
  const year = date.year();
  let month = date.month();
  const day = date.date();

  const monthList = [
    "Jan.",
    "Feb.",
    "Mar.",
    "Apr.",
    "May.",
    "Jun.",
    "Jul.",
    "Aug.",
    "Sept.",
    "Oct.",
    "Nov.",
    "Dec.",
  ];
  month = monthList.at(month) ?? "";

  if (withYear) {
    return month + " " + day + ", " + year;
  } else {
    return month + " " + day;
  }
};

export const formatDateEn2 = (stamp) => {
  const date =
    typeof stamp === "number" ? new Date(stamp * 1000) : new Date(stamp);
  const year = date.getFullYear();
  let month = date.getMonth();
  const day = date.getDate();
  let week = date.getDay();

  switch (week) {
    case 0:
      week = "Sunday";
      break;
    case 1:
      week = "Monday";
      break;
    case 2:
      week = "Tuesday";
      break;
    case 3:
      week = "Wednesday";
      break;
    case 4:
      week = "Thursday";
      break;
    case 5:
      week = "Friday";
      break;
    case 6:
      week = "Saturday";
      break;
    default:
      week = "";
  }

  switch (month) {
    case 0:
      month = "January";
      break;
    case 1:
      month = "February";
      break;
    case 2:
      month = "March";
      break;
    case 3:
      month = "April";
      break;
    case 4:
      month = "May";
      break;
    case 5:
      month = "June";
      break;
    case 6:
      month = "July";
      break;
    case 7:
      month = "August";
      break;
    case 8:
      month = "September";
      break;
    case 9:
      month = "October";
      break;
    case 10:
      month = "November";
      break;
    case 11:
      month = "December";
      break;
    default:
      month = "";
  }

  return week + ", " + month + " " + day + ", " + year;
};

export const compareDate = (stamp) => {
  const date =
    typeof stamp === "number" ? new Date(stamp * 1000) : new Date(stamp);
  const year = date.getFullYear();
  const month = "0" + (date.getMonth() + 1);
  const day = "0" + date.getDate();
  const strDate = year + "-" + month.slice(-2) + "-" + day.slice(-2);

  return new Date(strDate);
};

export const isSameDate = (stamp) => {
  const today = new Date();
  const date =
    typeof stamp === "number" ? new Date(stamp * 1000) : new Date(stamp);

  return (
    today.getFullYear() === date.getFullYear() &&
    today.getMonth() === date.getMonth() &&
    today.getDate() === date.getDate()
  );
};

export const isSameYear = (stamp) => {
  const today = new Date();
  const date =
    typeof stamp === "number" ? new Date(stamp * 1000) : new Date(stamp);

  return today.getFullYear() === date.getFullYear();
};
